import * as withAmplitudeClient from "@unlockre/utils-amplitude/dist/amplitude-client";

import environmentVariables from "@/utils/environment/environment-variables";

const amplitudeClient = withAmplitudeClient.createIfBrowser({
  apiKey: environmentVariables.AMPLITUDE_API_KEY,
  apiUrl: environmentVariables.AMPLITUDE_API_URL,
  sourceName: "keyhub"
});

export {amplitudeClient};
