import {PageRenderer} from "@unlockre/page-tools/dist/";
import type {PageWithLayoutAppProps} from "@unlockre/page-tools/dist/types";
import {useTrackAuth0User} from "@unlockre/utils-amplitude/dist";

type Props = {
  appProps: PageWithLayoutAppProps;
};

const Page = (props: Props) => {
  useTrackAuth0User();

  return <PageRenderer {...props} />;
};

export {Page};
