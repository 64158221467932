import Head from "next/head";

type Props = {
  pageTitle: string;
};

const PageHead = ({pageTitle}: Props) => (
  <Head>
    <title>{pageTitle}</title>
  </Head>
);

export {PageHead};
