/* eslint-disable react/jsx-max-depth */
import ThemeProvider from "@unlockre/components-library/dist/theme-provider";
import {PageAuth0Provider} from "@unlockre/page-tools/dist";
import type {PageWithLayoutAppProps} from "@unlockre/page-tools/dist";
import {AmplitudeClientProvider} from "@unlockre/utils-amplitude/dist";
import {SplitFactory} from "@splitsoftware/splitio-react";

import {Page} from "@/components/page";
import {amplitudeClient} from "@/utils/amplitude/amplitude-client";
import environmentVariables from "@/utils/environment/environment-variables";
import {splitIoFactory} from "@/utils/split-io/split-io-factory";

import {ApiClientsProvider} from "../api-clients-provider/api-clients-provider";

import GlobalStyle from "./global-style";

const Application = (appProps: PageWithLayoutAppProps) => (
  <>
    <GlobalStyle />
    <PageAuth0Provider
      audience={environmentVariables.AUTH0_AUDIENCE}
      clientId={environmentVariables.AUTH0_CLIENT_ID}
      domain={environmentVariables.AUTH0_DOMAIN}
    >
      <SplitFactory factory={splitIoFactory}>
        <ApiClientsProvider>
          <AmplitudeClientProvider {...{amplitudeClient}}>
            <ThemeProvider>
              <Page {...{appProps}} />
            </ThemeProvider>
          </AmplitudeClientProvider>
        </ApiClientsProvider>
      </SplitFactory>
    </PageAuth0Provider>
  </>
);

export default Application;
