const environmentNames = {
  ci: "ci",
  development: "development",
  local: "local",
  production: "production",
  staging: "staging"
} as const;

const allEnvironmentNames = [
  environmentNames.ci,
  environmentNames.development,
  environmentNames.local,
  environmentNames.production,
  environmentNames.staging
] as const;

type EnvironmentName = (typeof environmentNames)[keyof typeof environmentNames];

export {allEnvironmentNames, environmentNames};

export type {EnvironmentName};
