import {z} from "zod";

import {allEnvironmentNames} from "./environment-name";

/* eslint-disable @typescript-eslint/naming-convention */
const environmentVariablesSchema = z.object({
  AMPLITUDE_API_KEY: z.string().min(1, {message: "Required"}),
  AMPLITUDE_API_URL: z.string().optional(),
  APP_ENV: z.enum(allEnvironmentNames),
  AUTH0_AUDIENCE: z.string().min(1, {message: "Required"}),
  AUTH0_CLIENT_ID: z.string().min(1, {message: "Required"}),
  AUTH0_DOMAIN: z.string().min(1, {message: "Required"}),
  ORGANIZATIONS_API_URL: z.string().min(1, {message: "Required"}),
  SPLIT_IO_BROWSER_API_KEY: z.string().min(1, {message: "Required"})
});

type EnvironmentVariables = z.infer<typeof environmentVariablesSchema>;

const environmentVariables = environmentVariablesSchema.parse({
  AMPLITUDE_API_KEY: process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY,
  AMPLITUDE_API_URL: process.env.NEXT_PUBLIC_AMPLITUDE_API_URL,
  APP_ENV: process.env.NEXT_PUBLIC_APP_ENV,
  AUTH0_AUDIENCE: process.env.NEXT_PUBLIC_AUTH0_AUDIENCE,
  AUTH0_CLIENT_ID: process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID,
  AUTH0_DOMAIN: process.env.NEXT_PUBLIC_AUTH0_DOMAIN,
  ORGANIZATIONS_API_URL: process.env.NEXT_PUBLIC_ORGANIZATIONS_API_URL,
  SPLIT_IO_BROWSER_API_KEY: process.env.NEXT_PUBLIC_SPLIT_IO_BROWSER_API_KEY
});

export default environmentVariables;

export type {EnvironmentVariables};
