import {OpenApiClientsProvider} from "@unlockre/open-api-client/dist/open-api-clients-context";

import type {ReactElement} from "react";

import {organizationsApiName} from "@unlockre/organizations-api-tools/dist/organizations-api-name";

import {organizationsApiClient} from "@/utils/api/organizations/organizations-api-client";

type Props = {
  children: ReactElement;
};

const apiClients = {
  [organizationsApiName]: organizationsApiClient
};

const ApiClientsProvider = ({children}: Props) => (
  <OpenApiClientsProvider value={apiClients}>{children}</OpenApiClientsProvider>
);

export {ApiClientsProvider};
